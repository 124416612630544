import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

declare const $: any;

export class Navigation extends AbstractComponent {
    public static selector: string = 'navigation';
    public open: boolean = true;
    public closeAttr: string = '[data-close-navigation]';
    public burgerMenu: any;

    public init() {
        this.burgerMenu = this.getComponentElement().find('[data-burger-menu]')
        this.setupClickEventListeners();
    }

    private toggleNavigation(e) {
        $('.layout').attr("open", this.open);
        this.burgerMenu.attr("open", this.open);
        this.open = !this.open;
    }

    private setupClickEventListeners() {
        this.burgerMenu.on('click', e => this.toggleNavigation(e));
        $(document).on('click', this.closeAttr, e => this.toggleNavigation(e));
    }
}

new ComponentLoader(Navigation);
